import { graphql, useStaticQuery, Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import { StaticImage } from "gatsby-plugin-image"
import banner from '../images/distributorBanner.jpg'
import DistributorTile from '../components/distributorTile'
import Seo from '../components/seo'
import { IoMdArrowDropdown } from '@react-icons/all-files/io/IoMdArrowDropdown'

const query = graphql`
  {
    allStrapiCountry {
      nodes {
        name
        distributors {
          name
          link
          location
          locationLink
          email
          number
        }
      }
    }
  }
`

export default function Distributors() {
  const data = useStaticQuery(query)
  const {
    allStrapiCountry: {
      nodes: countries
    }
  } = data;

  const handleView = (id) => {
    document.getElementById(id).classList.toggle("hidden")
  }

  return (
    <Layout>
      <Seo 
        title={'Find Authorized Distributors of Gazelle® Near You | Gazelle® Tools'}
        description={'Locate authorized Gazelle® distributors in the GCC & MENA region. Connect with trusted partners to get the best industrial tools and solutions.'}
      />
      <StaticImage src="../images/distributorBanner.jpg" className="h-36 md:h-64 w-full object-cover" alt="Where to Buy GAZELLE®" />
      <div className="py-8 px-8 bg-secondary-light w-full md:min-h-[600px] 2xl:min-h-[750px]">
        <h1 className=' text-2xl md:text-3xl 2xl:text-4xl py-2 text-primary'>Where to Buy GAZELLE®</h1>
        <p className='text-sm md:text-base 2xl:text-lg pb-8'>
        GAZELLE® is available through our authorized distributors listed below. If your country is not found below, please visit our <Link to="/contact-us" className='text-primary'> Contact Us</Link> page.
        </p>
        {countries.sort((a,b)=> a.name > b.name ? 1 : -1).map((country, i) => {
          if (country.distributors.length > 0) {
            return (
              <div>
                <button onClick={()=> handleView(i)} className='w-full flex items-center justify-between md:justify-start md:gap-5 pt-4 pb-2 border-b border-secondary '>
                  <h2 className="text-lg md:text-xl 2xl:text-2xl text-primary md:order-last">{country.name}</h2>
                  <span
                    className='py-1 flex justify-center w-6 h-6 border border-black rounded'
                  >
                    <IoMdArrowDropdown />
                  </span>
                </button>
                <div
                  id={i}
                  className="hidden grid pb-2 md:pb-0 md:grid-cols-3 sm:grid-cols-1 gap-8 pt-4"
                >
                  {country.distributors.map(distributor => {
                    return (
                      <DistributorTile
                        distributor={distributor}
                      />
                    )
                  })}

                </div>

              </div>
            )
          }
        })}
      </div>
    </Layout>
  )
}
